import styled from '@emotion/styled'
import { colors } from '../styles/variables'
import { Link } from 'gatsby'

const Button = styled.button`
  margin-top: 20px;
  border-radius: 3px;
  border: 2px solid white;
  background: none;
  padding: 10px 20px;
  font-size: 1.2em;
  transform: skew(-20deg);
  color: white;
  box-shadow: 5px 5px 20px 0 ${colors.primary};
  transition: 0.2s all;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 6px 6px 20px 0 ${colors.secondary};
  }

  span {
    display: inline-block;
    transform: skew(20deg);
  }
`
export const ButtonLinkExternal = styled(Button.withComponent('a'))`
  display: inline-block;
`
export const ButtonLink = styled(Button.withComponent(Link))`
  display: inline-block;
`
export default Button
