import * as React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import { colors } from '../../styles/variables'
import Button, { ButtonLink, ButtonLinkExternal } from '../Button'
import Guitar from '../svg/Guitar'
import { keyframes } from '@emotion/core'
import { APPLY_PATH, FB_PAGE_URL, IG_PAGE_URL, MAIL } from "../../data/urls";

const ApplySectionWrapper = styled.section`
  padding: 15vh 0 10vh 0;
  position: relative;
  h2 {
    margin-top: 10px;
    font-size: 4em;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: ${colors.black};
    text-shadow: 3px 3px 4px ${colors.secondary};
  }
  p {
    margin-top: 2em;
    line-height: 1.8em;
    font-size: 1.1em;
    @media (max-width: 1030px) {
      margin: 0;
    }
  }
`
const dash = keyframes`
to {
    stroke-dashoffset: 10000;
  }
`
const StyledGuitar = styled(Guitar)`
  fill: transparent;
  stroke: ${colors.secondary};
  stroke-width: 4px;
  stroke-dasharray: 1000;
  transform: rotate(-45deg);
  width: 100%;
  margin-top: 20%;
  animation: ${dash} 20s linear infinite;

  @media (max-width: 1030px) {
    display: none;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 10vh;

  @media (max-width: 1030px) {
    grid-template-columns: 1fr;
  }
`
const ApplySection = () => {
  return (
    <ApplySectionWrapper>
      <Container>
        <Grid>
          <div>
            <h2>Czekamy na Ciebie</h2>
            <p>
              Jeżeli chcesz wziąć udział w naszym wydarzeniu wystarczy że wypełnisz formularz zgłoszeniowy! Na zgłoszenia czekamy do 15
              maja. Macie pytania? Piszcie do nas na <a href={IG_PAGE_URL}>naszym Instagramie</a> lub na{' '}
              <a href={`mailto:${MAIL}`}>{MAIL}</a>
            </p>
            <ButtonLinkExternal href={APPLY_PATH} target="_blank" rel="noreferrer noopener">
              <span>Zgłoś się jako artysta</span>
            </ButtonLinkExternal>
          </div>
          <StyledGuitar />
        </Grid>
      </Container>
    </ApplySectionWrapper>
  )
}

export default ApplySection
