import styled from '@emotion/styled'

const Line = styled.span`
  height: 5px;
  background: #fcfbe7;
  box-shadow: 0 0 20px 10px rgb(224, 199, 22);
  border-radius: 3px;
  position: relative;
  display: block;
`
export default Line
