import * as React from "react";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import Container from "../Container";
import Line from "../Line";
import MainLayout from "../../layouts/MainLayout";
import { colors } from "../../styles/variables";

const AboutSectionWrapper = styled.section`
  padding: 10vh 0;
  // background: rgb(25, 25, 25);

  h2 {
    margin-top: 10px;
    font-size: 5em;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: ${colors.black};
    text-shadow: 3px 3px 4px ${colors.tertiary};
  }

  p {
    margin-top: 2em;
    line-height: 1.8em;
    font-size: 1.1em;
    @media (max-width: 1030px) {
      margin: 0;
    }
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;

  @media (max-width: 1030px) {
    grid-template-columns: 1fr;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  border-radius: 2px;
  @media (max-width: 1030px) {
    height: 40vh;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
    height: 100%;
    border: 2px solid #9f60da;
    box-shadow: 0 0 20px #9f60da;
    border-radius: inherit;
    z-index: -1;
  }

  img,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: inherit;
  }
`;
const AboutSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "1.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Container>
        <Line />
      </Container>
      <AboutSectionWrapper className="about-section">
        <Container>
          <h2>O ŻUKu</h2>
          <Grid>
            <div>
              <p>
                <strong>ŻUK</strong> - <strong>Ż</strong>eromski <strong>U</strong>słany <strong>K</strong>to szkolny
                festiwal XL Liceum Ogólnokształcącego im. Stefana Żeromskiego. Od pięciu lat spotykamy się każdego
                czerwca, aby spędzić wieczór z dobrą muzyką i wspierać początkujących wykonawców. W 2023 bawiło się z
                nami ponad 800 osób, a w tym mamy nadzieję na jeszcze więcej.

              </p>
              <p>
                Oprócz tego, w tym roku ponownie organizujemy konkurs dla artystów pragnących rozwijać swoją karierę i
                zdobywać serca publiczności. Nagrodą przyznawane będą przez jury jak i publiczność. Wykonawców chętnych
                do wzięcia w nim udziału zapraszamy do rejestracji!
              </p>
              <p>
                Tegorocznym motywem przewodnim są Hawaje, więc wskakuj w kwiecistą koszulę, nastrój się na wakacyjny
                klimat i przygotuj się na niezapomnianą zabawę!
              </p>
              <p>
                Widzimy się już 7 czerwca na boisku przy ulicy Platynowej 1.
              </p>
              <p>
                <span>Do zobaczenia!</span><br />
                <i>Team organizatorów </i>
              </p>
            </div>
            <ImageWrapper>
              <Img fluid={data.image.childImageSharp.fluid} />
            </ImageWrapper>
          </Grid>
        </Container>
      </AboutSectionWrapper>
    </>
  );
};

export default AboutSection;
