import * as React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Container from '../components/Container'
import MainLayout from '../layouts/MainLayout'
import Logo from '../components/Logo'
import { neonAnimation } from '../styles/animations'
import Button from '../components/Button'
import Img from 'gatsby-image'
import AboutSection from '../components/sections/AboutSection'
import ApplySection from '../components/sections/ApplySection'

const Hero = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  h1 {
    font-family: Montserrat;
    font-size: 9em;
  }
  p {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.8);
  }
`

const HeroGrid = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  @media (max-width: 950px) {
    display: block;
  }
`

const MainLogo = styled(Logo)`
  width: 600px;
  height: auto;
  margin-bottom: 20px;
  #online-text {
    animation: ${neonAnimation} 5s linear infinite;
  }
  @media (max-width: 1350px) {
    width: 400px;
    margin-top: 10vh;
  }
`
const ImageWrapper = styled.div`
  width: 50%;
  margin-left: 3em;
  @media (max-width: 950px) {
    margin: 3em 0 0 0;
    width: 100%;
    object-fit: cover;
  }
  .gatsby-image-wrapper {
    @media (max-width: 950px) {
      height: 30vh;
      object-fit: cover;
    }
  }
`
const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "rok_temu.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const learnMore = () => {
    if (typeof window === 'undefined') return
    ;(document.querySelector('.about-section') as HTMLDivElement).scrollIntoView({
      behavior: 'smooth'
    })
  }
  return (
    <MainLayout>
      <Hero>
        <Container>
          <HeroGrid>
            <div>
              <MainLogo />

              <p>Festiwal licealny wspierający młodych artystów</p>
              <h4 style={{fontSize: '1.8em'}}>7 czerwca 17:00, Platynowa 1 - boisko</h4>
              <Button onClick={learnMore}>
                <span>Dowiedz się więcej</span>
              </Button>
            </div>
            <ImageWrapper>
              <Img fluid={data.image.childImageSharp.fluid} />
            </ImageWrapper>
          </HeroGrid>
        </Container>
      </Hero>
      <AboutSection />
      <ApplySection />
    </MainLayout>
  )
}

export default AboutPage
